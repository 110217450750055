import React, { useLayoutEffect } from "react";
import { Collapse, initTE } from "tw-elements";

function Headernav(props) {
  useLayoutEffect(() => {
    initTE({ Collapse });
  }, []);
  return (
    <div className="fixed top-0 z-50 w-full">
      {/* Main navigationm-auto md:container */}

      <nav
        className="relative m-auto  mt-5 flex w-5/6 flex-nowrap items-center  rounded-full border bg-[#FBFBFB] bg-gradient-to-r p-[3px] text-neutral-500 shadow-lg hover:text-neutral-700 focus:text-neutral-700 dark:bg-neutral-600 max-md:w-11/12 lg:h-16 lg:flex-wrap lg:justify-between"
        data-te-navbar-ref=""
      >
        <div className="flex w-full flex-row items-center justify-between rounded-full bg-white p-[6px] px-[6px] md:h-full">
          <div className="ml-2 w-40">
            {/* <a className="" href="https://app.creativecat.io/"> */}
            <img
              src={require("../../asset/CreativecatLogo.png")}
              className="w-full"
              // className="w-full"
            />
            {/* </a> */}
          </div>
          {/* <a className="" href="https://app.creativecat.io/"> */}
          <a className="" href="https://forms.gle/GonXUWa9oQr6gh9m6">
            <button className="  hidden rounded-full bg-gradient-to-r  from-[#008cd6] to-[#0054ad] px-8 py-1 font-semibold text-white max-md:inline-block">
              Join
            </button>
          </a>

          {/* Collapsible navbarm-auto md:container */}
          <div
            className="!visible mt-2 hidden flex-1 items-center max-md:absolute max-md:right-10 max-md:top-8 max-md:w-64 max-md:bg-white max-md:p-3 md:block lg:mt-0 lg:!flex"
            id="navbarSupportedContent3"
            data-te-collapse-item=""
          >
            {/* Left links */}
            <div
              className="list-style-none  flex flex-1 flex-col justify-center pl-0 lg:flex-row"
              data-te-navbar-nav-ref=""
              onClick={(e) => props.menuHandleClick(e, "feature")}
            >
              {/* Features link */}
              <div className="px-2 py-2 lg:px-10" data-te-nav-item-ref="">
                <a
                  className="p-0 font-semibold text-neutral-500 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
                  aria-current="page"
                  href="#"
                  data-te-nav-link-ref=""
                >
                  Features
                </a>
              </div>
              {/*  Use Cases link */}
              <div
                className="px-2 py-2 lg:px-10"
                data-te-nav-item-ref=""
                onClick={(e) => props.menuHandleClick(e, "usecase")}
              >
                <a
                  className="p-0 font-semibold text-neutral-500 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
                  href="#"
                  data-te-nav-link-ref=""
                >
                  Use Cases
                </a>
              </div>
              {/* Pricing link */}
              <div
                className="px-2 py-2 lg:px-10"
                data-te-nav-item-ref=""
                onClick={(e) => props.menuHandleClick(e, "pricing")}
              >
                <a
                  className="p-0 font-semibold text-neutral-500 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
                  href="#"
                  data-te-nav-link-ref=""
                >
                  Pricing
                </a>
              </div>
            </div>
            <div>
              <a
                // href="https://app.creativecat.io/"
                href="https://forms.gle/GonXUWa9oQr6gh9m6"
                target="_blank"
                className="  rounded-full  bg-gradient-to-r from-[#008cd6] to-[#0054ad] px-10 py-2 font-semibold text-white"
              >
                Join
              </a>
            </div>
          </div>
          {/* Hamburger button for mobile view */}
          <button
            className="block hidden border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
            type="button"
            data-te-collapse-init=""
            data-te-target="#navbarSupportedContent3"
            aria-controls="navbarSupportedContent3"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={(e) => props.menuHandleClick(e, "menuButton")}
          >
            {/* Hamburger icon */}
            <span className="[&>svg]:w-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-7 w-7"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </button>
        </div>
      </nav>
    </div>
  );
}

export default Headernav;
