import React from "react";

function Aicard(props) {
  return (
    <section
      className=" bg-gradient-to-b from-white from-0% from-50% to-black to-10% to-50% py-5  md:py-20"
      ref={props.featureRef}
    >
      <div className="m-auto  p-10 md:container">
        <div className="bg-gradient-from-tl  m-auto  max-w-4xl rounded-3xl bg-gradient-to-br from-[#28209b]  to-[#829eff] p-7 text-white">
          <div class="flex flex-row">
            <div className="basis-1/2">
              <h4 className="text-base">AI Content Creation</h4>
              <h4 className="my-5 text-3xl   font-semibold   leading-tight md:text-6xl">
                Limitless <br />
                Creative <br />
                Possibilities
              </h4>
            </div>
            <div className="flex basis-1/2 items-center justify-center">
              <img
                src={require("../../asset/landingPage/paw.png")}
                alt="close"
              />
            </div>
          </div>
          <p className="w-4/5">
            With CreativeCat's powerhouse features, the sky's the limit for your
            content. Automate daily posts, fuel creativity, and design custom
            templates to stand out. Discover the magic of content creation.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Aicard;
