import React, { useEffect } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function CancellationRefundPolicy(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="py-10">
        <div className="m-auto md:container">
          <div className="m-auto mb-8 flex  w-10/12 items-center justify-between border-b-2 border-slate-400 py-5">
            <Link to="/">
              <img
                alt="creativeCat"
                className="logoWhite"
                width="140px"
                src={require("../../src/asset/CreativecatLogo.png")}
              ></img>
            </Link>
            <h4 className="text-2xl font-semibold">Refund Policy</h4>
          </div>
          <div className="ml-auto mr-auto w-8/12 font-semibold" md="8">
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">1. Cancellation Policy</h5>
              <p className="text-sm  font-medium">
                At CreativeCat, we understand that circumstances may change, and
                you may need to cancel your subscription. We aim to make the
                cancellation process as straightforward as possible:
              </p>
            </div>

            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">2. Cancellation Process</h5>
              <p className="text-sm  font-medium">
                To cancel your subscription, please log in to your CreativeCat
                account and navigate to the "Account Settings" section. Follow
                the instructions provided to initiate the cancellation process.
              </p>
            </div>

            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">3. No Long-Term Commitments</h5>
              <p className="text-sm  font-medium">
                We do not impose any long-term commitments on our users. You can
                cancel your subscription at any time.
              </p>
            </div>

            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">4. No Cancellation Fees</h5>
              <p className="text-sm  font-medium">
                We do not charge any cancellation fees. You can cancel your
                subscription without incurring any additional charges.
              </p>
            </div>

            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">
                5. Effective Date of Cancellation
              </h5>
              <p className="text-sm  font-medium">
                When you cancel your subscription, it will remain active until
                the end of your current billing cycle. You will continue to have
                access to CreativeCat during this time.
              </p>
            </div>

            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">6. No Partial Refunds</h5>
              <p className="text-sm  font-medium">
                Please note that we do not offer partial refunds for unused
                portions of your subscription term.
              </p>
            </div>

            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">7. No Refunds</h5>
              <p className="text-sm  font-medium">
                We do not offer refunds for any portion of your subscription
                fees, including but not limited to unused days or months of
                service.
              </p>
            </div>

            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">8. Exception</h5>
              <p className="text-sm  font-medium">
                In the unlikely event of a billing error or an issue caused by
                CreativeCat, please contact our customer support team at&nbsp;
                <a
                  href="mailto:support@creativecat.io"
                  className="text-blue-700"
                >
                  support@creativecat.io
                </a>
                , and we will promptly address the issue and provide a
                resolution.
              </p>
            </div>

            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">9. Contact Us</h5>
              <p className="text-sm  font-medium">
                If you have any questions or concerns regarding our Cancellation
                & Refund Policy or any other aspect of our services, please
                contact us at{" "}
                <a
                  href="mailto:support@creativecat.io"
                  className="text-blue-700"
                >
                  support@creativecat.io
                </a>
                . We are here to assist you and address any inquiries you may
                have.
              </p>
            </div>

            <br />
            <br />
            <h5 className="text-center">Thank you for using CreativeCat!</h5>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default CancellationRefundPolicy;
