import React, { useEffect, useRef, useState } from "react";
import Headernav from "./home/Headernav";
import UnlockSection from "./home/UnlockSection";
import CreativeInAction from "./home/CreativeInAction";
import Companylist from "./home/Companylist";
import Aicard from "./home/Aicard";
import GameChanging from "./home/GameChanging";
import DynamicIdeaGeneration from "./home/DynamicIdeaGeneration";
import UnlimitedCustomTemplate from "./home/UnlimitedCustomTemplate";
import DiscoverSection from "./home/DiscoverSection";
import UserSection from "./home/UserSection";
import Pricing from "./home/Pricing";
import Faq from "./home/Faq";
import AboutCreative from "./home/AboutCreative";
import Footer from "./Footer";
import ScrollToTop from "react-scroll-to-top";
import { IoMdArrowUp } from "react-icons/io";
import "../asset/css/modal-video.css";

function CreativeCatHome(props) {
  const featureRef = useRef(null);
  const usecaseRef = useRef(null);
  const pricingRef = useRef(null);

  const menuHandleClick = (e, menuString) => {
    e.preventDefault();
    e.stopPropagation();
    if (menuString === "feature") {
      featureRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (menuString === "usecase") {
      usecaseRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (menuString === "pricing") {
      pricingRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (menuString === "menuButton") {
    } else {
      return;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Headernav menuHandleClick={menuHandleClick} />
      <div>
        <UnlockSection />
        <CreativeInAction />
        <Companylist />
        <Aicard featureRef={featureRef} />
        <GameChanging />
        <DynamicIdeaGeneration />
        <UnlimitedCustomTemplate />
        <DiscoverSection usecaseRef={usecaseRef} />
        <UserSection />
        <Pricing pricingRef={pricingRef} />
        <Faq />
        <AboutCreative />
        <Footer />
      </div>
      <ScrollToTop smooth component={<IoMdArrowUp />} />
    </>
  );
}

export default CreativeCatHome;
