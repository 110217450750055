import React from "react";

import { Link } from "react-router-dom";
import { FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function Footer(props) {
  return (
    <footer className=" bg-black">
      <div className=" m-auto m-auto px-5 py-6 text-white md:container md:p-6 md:px-3">
        <div className="flex flex-col items-center justify-center justify-between gap-4 md:flex-row md:gap-0">
          <div className="w-full md:w-auto">
            <Link to="/">
              <img
                alt="creativeCat"
                className="logoWhite mr-auto"
                width="140px"
                src={require("../../src/asset/Creativecat Logo White.png")}
              ></img>
            </Link>
          </div>
          <div className="flex w-full flex-col md:w-auto md:flex-row md:justify-between">
            <Link to="/terms-of-service">
              <span className="flex py-1 md:px-5 md:py-0">
                Terms of Service
              </span>
            </Link>
            <Link to="/privacy-policy">
              <span className="flex py-1 md:px-5 md:py-0">Privacy</span>
            </Link>
            <Link to="/cancellation-refundPolicy">
              <span className="flex py-1 md:px-5 md:py-0">Refund Policy</span>
            </Link>
            <Link to="/support">
              <span className="flex py-1 md:px-5 md:py-0">Support</span>
            </Link>
          </div>
          <div className="flex gap-8">
            <a
              className=""
              target="_blank"
              href="https://x.com/creativecat_io?s=21"
            >
              <FaXTwitter className="w-5" />
            </a>
            <a
              className=""
              target="_blank"
              href="https://www.linkedin.com/company/creativecat-io/"
            >
              <FaLinkedin className="w-5" />
            </a>
            <a
              className=""
              target="_blank"
              href="https://www.instagram.com/creativecat.io/?igsh=MXZrc2dscWMweXk5YQ%3D%3Ds"
            >
              <FaInstagram className="w-5" />
            </a>
            <a
              className=""
              target="_blank"
              href="https://youtube.com/@CreativeCat_io?si=2NJ-YI7CeQWDl30e"
            >
              <FaYoutube className="w-5" />
            </a>
          </div>
          <div>
            <p>@2024 CreativeCat.io</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
