import React, { useState } from "react";
import ModalVideo from "react-modal-video";

function DynamicIdeaGeneration(props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="py-5 md:py-20">
        <div className=" m-auto md:container">
          <>
            <h2 className="my-2 mb-5  text-center  text-3xl font-semibold  leading-relaxed text-black  md:text-6xl">
              Dynamic Idea Generator
            </h2>
            <p className="m-auto w-5/6 text-center text-xl font-semibold text-gray-400 md:w-7/12">
              Stuck in a creative rut? No worries! CreativeCat's dynamic idea
              generation tool is here to rescue your creativity. Our{" "}
              <span className="text-black">AI-powered feature</span> provides
              you with a continuous flow of content ideas that match your
              vision. From blog topics to{" "}
              <span className="text-black">social media posts</span>,
              inspiration is just a{" "}
              <span className="text-black">click away</span>.
            </p>

            {/* <div className="flex items-center justify-center">
              <button className="my-6 flex items-center justify-between rounded bg-gradient-to-r from-[#008cd6] to-[#0054ad] px-4 py-2 font-semibold text-white">
                Get CreativeCat &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img
                  src={require("../../asset/landingPage/plus.png")}
                  alt="plus"
                />
              </button>
            </div> */}

            <div className="mt-6 flex items-center justify-center font-semibold">
              <div>
                <p className="text-3xl text-green-400">CreativeCat</p>
                <img
                  src={require("../../asset/landingPage/Path.png")}
                  alt="bracket"
                />

                <p className="text-center text-sm text-violet-400">Brand</p>
              </div>
              <div className="align-top">
                <p className="mx-5 text-3xl text-teal-400">+</p>
              </div>
              <div className="text-center">
                <p className="text-3xl text-cyan-400">Social Media</p>
                <img
                  className="m-auto"
                  src={require("../../asset/landingPage/Path.png")}
                  alt="bracket"
                />
                <p className="text-center text-sm text-fuchsia-400">
                  Dynamic Idea
                </p>
              </div>

              <br />
            </div>

            <div className="m-auto mt-10 flex md:w-7/12">
              <div className="grid grid-cols-2 gap-4 p-5 md:grid-cols-2 md:p-0">
                <div className="rounded bg-cardbg1 bg-cover bg-center bg-no-repeat p-5 py-7 text-white">
                  <h5 className="text-xl ">AI vs. Human Writing</h5>
                  <p className="mt-4 text-base tracking-wider">
                    Run a series comparing AI-generated content with
                    human-written content, showcasing the strengths of
                    CreativeCat.
                  </p>
                </div>
                <div className="rounded bg-cardbg2 bg-cover bg-center bg-no-repeat p-5 py-7 text-white">
                  <h5 className="text-xl ">Content Challenges</h5>
                  <p className="mt-4 text-base tracking-wider">
                    Challenge followers to generate creative content using
                    CreativeCat, with prizes for the best entries.
                  </p>
                </div>
                <div className="rounded bg-cardbg3 bg-cover bg-center bg-no-repeat p-5 py-7 text-white">
                  <h5 className="text-xl ">Tutorial Videos</h5>
                  <p className="mt-4 text-base tracking-wider">
                    Share short video tutorials demonstrating how to use
                    specific features of CreativeCat effectively.
                  </p>
                </div>
                <div className="rounded bg-cardbg4 bg-cover bg-center bg-no-repeat p-5 py-7 text-white">
                  <h5 className="text-xl ">Interactive Quizzes</h5>
                  <p className="mt-4 text-base tracking-wider">
                    Create quizzes to help users discover their writing style
                    and how CreativeCat can enhance it.
                  </p>
                </div>
                <div className="rounded bg-cardbg5 bg-cover bg-center bg-no-repeat p-5 py-7 text-white">
                  <h5 className="text-xl ">Feature User Testimonials</h5>
                  <p className="mt-4 text-base tracking-wider">
                    Share success stories and testimonials from users who have
                    experienced improved content creation with CreativeCat.
                  </p>
                </div>
                <div className="rounded bg-cardbg6 bg-cover bg-center bg-no-repeat p-5 py-7 text-white">
                  <h5 className="text-xl ">Behind-the-Scenes</h5>
                  <p className="mt-4 text-base tracking-wider">
                    Offer a glimpse into the development and workings of the AI
                    technology powering CreativeCat.
                  </p>
                </div>
              </div>
            </div>
            <button
              className="m-auto mt-3 flex items-center justify-between rounded-full bg-[#424242] p-2 px-2 pl-6 text-lg font-semibold text-white md:mt-10"
              onClick={(e) => {
                setOpen(true);
              }}
            >
              Watch &nbsp;&nbsp;
              <img
                src={require("../../asset/landingPage/plus.png")}
                alt="plus"
                className="w-10"
              />
            </button>
          </>

          <></>
        </div>
      </section>

      {/* {props.movelVal === 2 && (
        <VideoModel movelVal={props.movelVal} setMovelVal={props.setMovelVal} />
      )} */}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="22opk2OCoRU"
        onClose={() => setOpen(false)}
      />
    </>
  );
}

export default DynamicIdeaGeneration;
