import React, { useEffect, useState } from "react";
import { Collapse, initTE } from "tw-elements";

function Faq(props) {
  const [faqData, setFaqdata] = useState([
    {
      id: 1,
      question: "What is CreativeCat?",
      answer:
        "CreativeCat is a versatile content generation platform that simplifies the process of creating engaging content for your blog, social media, and more. It offers a range of tools and features to help you unleash your creativity.",
    },
    {
      id: 2,
      question: "How does CreativeCat work?",
      answer:
        "CreativeCat utilizes AI-powered tools to automate content creation. You can use features like the dynamic idea generator, automated social media content generator, and custom template creation to streamline the content generation process.",
    },
    {
      id: 3,
      question: "Can I try CreativeCat for free?",
      answer:
        "Yes, we offer a Free Plan that allows you to experience the basic features of CreativeCat. You can explore its capabilities and decide if it's the right fit for your content creation needs.",
    },
    {
      id: 4,
      question: "What are the benefits of the Premium Plan?",
      answer:
        "The Premium Plan offers unlimited word usage, access to all features, and is ideal for content creators with extensive content generation requirements. It provides the most flexibility and freedom to create content.",
    },
    {
      id: 5,
      question: "Is my data secure with CreativeCat?",
      answer:
        "Yes, we take user data security seriously. We use industry-standard encryption and follow best practices to ensure your data is protected. You can review our Privacy Policy for more details.",
    },
    {
      id: 6,
      question:
        "What happens if I run out of words in my Basic or Standard plan?",
      answer:
        "If you run out of words in your Basic or Standard plan during your subscription cycle, you have the option to 'Recharge Words.' This feature allows you to add more words to your existing plan without having to upgrade to a higher plan. It's a convenient way to ensure you can continue generating content within the same subscription cycle. The additional words are typically available for immediate use.",
    },
    {
      id: 7,
      question: "Can I cancel my subscription at any time?",
      answer:
        "Yes, you have the flexibility to cancel your subscription at any time without any cancellation fees. Your access will remain active until the end of your current billing cycle.",
    },
    {
      id: 8,
      question:
        "Do you offer integration options for third-party tools or services?",
      answer:
        "We are continuously working on expanding our integrations. Please reach out to our support team to inquire about specific integration options.",
    },
    {
      id: 9,
      question: "How can CreativeCat benefit my industry or niche?",
      answer:
        "CreativeCat is designed to be versatile and can be tailored to various industries. It simplifies content creation, saving time and effort. Reach out to us to discuss how it can specifically benefit your niche.",
    },
    {
      id: 10,
      question:
        "Is there a limit to the number of projects I can work on with CreativeCat?",
      answer:
        "Depending on your chosen plan, you may have limits on the number of projects. The Premium Plan offers Unlimited Projects capabilities, while other plans have specific project limits.",
    },
    {
      id: 11,
      question: "How can I get in touch with your support team?",
      answer:
        "You can reach out to our support team through the 'Contact' page on our website. We're here to assist you with any questions or concerns you may have.",
    },
  ]);
  useEffect(() => {
    initTE({ Collapse });
  }, []);

  return (
    <section className="py-0 md:py-10">
      <div className=" m-auto md:container ">
        <h2 className="m-auto my-2 w-full pt-5 text-center text-3xl font-semibold leading-tight text-black  md:mb-10   md:w-8/12   md:p-0 md:p-5 md:text-6xl">
          Frequently Asked Questions
        </h2>

        <div className="m-auto w-full p-5 md:my-2 md:mb-10 md:w-8/12 md:p-0">
          <div id="accordionExample">
            {faqData.map((faq) => {
              return (
                <div class="border border-l-0 border-r-0 border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
                  <h2 class="mb-0" id={`heading${faq.id}`}>
                    <button
                      class="group relative flex w-full items-center rounded-none border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                      type="button"
                      data-te-collapse-init
                      data-te-collapse-collapsed
                      data-te-target={`#collapse${faq.id}`}
                      aria-expanded="false"
                      aria-controls={`collapse${faq.id}`}
                    >
                      <span className="text-xl font-semibold">
                        {faq.question}
                      </span>
                      <span class="ml-auto h-5 w-10 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="ml-auto h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    </button>
                  </h2>
                  <div
                    id={`collapse${faq.id}`}
                    class="!visible hidden"
                    data-te-collapse-item
                    aria-labelledby={`heading${faq.id}`}
                    data-te-parent="#accordionExample"
                  >
                    <div class="px-5 py-4">
                      <span className="block w-10/12 pl-4 text-lg font-semibold">
                        {faq.answer}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
