import { useState } from "react";

function UnlockSection(props) {
  const [val, setVal] = useState("linkedin");
  const channelVal = {
    linkedin: `<p>🚀 <b className="font-semibold">Unleash Your Creativity with CreativeCat!</b> 🎨</p>
    <br />
   <p>
     📚 Are you ready to take your content creation to the next
     level? Say goodbye to writer's block and hello to unlimited
     inspiration! 🌟
   </p>
   <p>👉 Learn More 📆 Limited-time offer!</p>
   <br />
   <h6><b className="font-semibold">Image:</b></h6>
   <p>
     A visually appealing image that showcases a user-friendly
     interface with a variety of content creation options and an
     easy-to-navigate dashboard.
   </p>`,
    facebook: `<p><b className="font-semibold">🌟 Unleash Your Writing Potential with CreativeCat!</b> 🚀 Elevate your content creation game effortlessly. 📝</p>
    <br/>
    <h6><b className="font-semibold">🎨 Dive into a world of creativity:</b></h6>
    <ul>
    <li>✅ Seamless Collaboration</li>
    <li>✅ AI-powered Suggestions</li>
    <li>✅ Endless Inspiration</li>
    </ul>
        
    <p>🔥 Transform your ideas into captivating content! 🚀 Try CreativeCat FREE today! 🌐</p>
    <br /> <p>#CreativeWriting #ContentCreation #UnlockCreativity 🚀📚</p>
    `,
    song: `<h6><b className="font-semibold">(Verse 1)</b></h6>
    <p>In the realm of words, where ideas ignite,
    CreativeCat takes flight, in the digital light.
    A content wizard, weaving tales so bold,
    Unleashing stories, more precious than gold.
    <p>
    <br />
    <h6><b className="font-semibold">(Chorus)</b></h6>
    <p>Oh, CreativeCat, your pen's a magic wand,
    In the world of creation, you're beyond,
    With words as your canvas, and ideas so divine,
    You paint the tapestry of content, so fine.</p>`,
    blog: `<p>🚀 <b className="font-semibold">Unleash your creativity with CreativeCat!</b> 
    </p>
    <br/> <p>Say goodbye to writer's block and craft compelling stories effortlessly. Whether a seasoned writer or beginner, it's your trusted companion for limitless possibilities. 🌟 Ignite your imagination and elevate your writing experience! 🖋️ </p>
    <br />
    <p>#CreativeCat #ContentCreation #WritingMagic</p>
   
    <p>#CreativeCat #ContentCreation #WritingMagic</p>`,
    story: `<p>In a quaint town, a mysterious feline named Whisker, known as the Creative Cat, roamed the streets. Whisker's magical purrs sparked inspiration, turning ordinary objects into extraordinary art. The town flourished with creativity, thanks to the enchanting presence of the one and only Creative Cat. </p>`,
    product: `<p>Meet our Talking Cat Toy – the purr-fect companion for endless feline fun! With realistic meows, touch-sensitive sensors, and durable design, it engages your cat in playful interactions. Bring joy and laughter to your furry friend's day!</p>`,
  };

  return (
    <section className="md:h-full">
      <div className="m-auto md:container">
        <div className="mt-28 md:mt-36">
          <h2 className="my-2 text-center text-3xl font-bold text-black md:text-6xl  ">
            Unlock Your Creative
          </h2>
          <h2 className="my-2 text-center text-3xl font-bold text-black md:text-6xl ">
            Potential With{" "}
            <span className="bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent">
              CreativeCat
            </span>
          </h2>
        </div>
        <div className="relative m-auto mt-12 w-11/12 rounded-md md:mb-10 md:mt-16 md:w-7/12 md:border  md:border-2 md:border-black md:p-3">
          <img
            src={require("../../asset/landingPage/macclose.png")}
            alt="close"
            className="hidden md:inline-block"
          />
          <div class="flex flex-col md:flex-row">
            <div class="order-2 basis-full md:order-1 md:basis-4/12">
              <div className=" bannerScroll mb-5 mt-5 flex  gap-4 overflow-auto md:mt-20 md:block">
                <div className="flex flex-none items-center">
                  <button
                    className={
                      val === "linkedin"
                        ? "z-10 my-2 flex flex-none cursor-pointer items-center justify-center rounded-full  border border-blue-700 bg-gradient-to-r from-[#008cd6] to-[#0054ad]   p-2 text-sm font-semibold text-white md:ml-16"
                        : "z-10 my-2 flex flex-none items-center justify-center rounded-full border  border-gray-400 bg-white p-2 text-sm font-semibold md:ml-16"
                    }
                    onClick={(e) => setVal("linkedin")}
                  >
                    <img
                      className="mr-1"
                      src={require("../../asset/landingPage/Icon/linkedin.png")}
                      alt="linkedin"
                    />
                    &nbsp;
                    <span>LinkedIn Post </span>
                    &nbsp;
                  </button>
                  <hr
                    className={
                      val === "linkedin"
                        ? "absolute left-[24%] hidden w-[15%] border-2 border-dashed border-[#008cd6] md:block"
                        : "absolute left-[24%] hidden w-[15%] border-2 border-dashed md:block"
                    }
                  />
                </div>
                <div className="flex flex-none items-center">
                  <button
                    className={
                      val === "facebook"
                        ? "z-10 my-2 flex flex-none cursor-pointer items-center justify-center rounded-full  border border-blue-700 bg-gradient-to-r from-[#008cd6] to-[#0054ad]   p-2 text-sm font-semibold text-white md:ml-16"
                        : "z-10 my-2 flex flex-none items-center justify-center rounded-full border  border-gray-400 bg-white p-2 text-sm font-semibold  md:ml-16"
                    }
                    onClick={(e) => setVal("facebook")}
                  >
                    <img
                      className="mr-1"
                      src={require("../../asset/landingPage/Icon/facebook.png")}
                      alt="facebook"
                    />
                    &nbsp;
                    <span>Facebook Ad </span>
                    &nbsp;
                  </button>
                  <hr
                    className={
                      val === "facebook"
                        ? "absolute left-[23%] hidden w-[19%] border-2 border-dashed border-[#008cd6] md:block"
                        : "absolute left-[23%] hidden w-[19%] border-2 border-dashed md:block"
                    }
                  />
                </div>
                <div className="flex flex-none items-center">
                  <button
                    className={
                      val === "song"
                        ? "z-10 my-2 flex flex-none cursor-pointer items-center justify-center rounded-full  border border-blue-700 bg-gradient-to-r from-[#008cd6] to-[#0054ad]   p-2 text-sm font-semibold text-white md:ml-16"
                        : "z-10 my-2 flex flex-none items-center justify-center rounded-full border  border-gray-400 bg-white p-2 text-sm font-semibold  md:ml-16"
                    }
                    onClick={(e) => setVal("song")}
                  >
                    <img
                      className="mr-1"
                      src={require("../../asset/landingPage/Icon/song.png")}
                      alt="song lyrics"
                    />
                    &nbsp;
                    <span>Song Lyrics </span>
                    &nbsp;
                  </button>
                  <hr
                    className={
                      val === "song"
                        ? "absolute left-[22%] hidden w-[13%] border-2 border-dashed border-[#008cd6] md:block"
                        : "absolute left-[22%] hidden w-[13%] border-2 border-dashed md:block"
                    }
                  />
                </div>
                <div className="flex flex-none items-center">
                  <button
                    className={
                      val === "blog"
                        ? "z-10 my-2 flex flex-none cursor-pointer items-center justify-center rounded-full  border border-blue-700 bg-gradient-to-r from-[#008cd6] to-[#0054ad]   p-2 text-sm font-semibold text-white md:ml-16"
                        : "z-10 my-2 flex flex-none items-center justify-center rounded-full border  border-gray-400 bg-white p-2 text-sm font-semibold  md:ml-16"
                    }
                    onClick={(e) => setVal("blog")}
                  >
                    <img
                      className="mr-1"
                      src={require("../../asset/landingPage/Icon/blog.png")}
                      alt="blog intro"
                    />
                    &nbsp;
                    <span>Blog Intro </span>
                    &nbsp;
                  </button>
                  <hr
                    className={
                      val === "blog"
                        ? "absolute left-[21%] hidden w-[21%] border-2 border-dashed border-[#008cd6] md:block"
                        : "absolute left-[21%] hidden w-[21%] border-2 border-dashed md:block"
                    }
                  />
                </div>
                <div className="flex flex-none items-center">
                  <button
                    className={
                      val === "story"
                        ? "z-10 my-2 flex flex-none cursor-pointer items-center justify-center rounded-full  border border-blue-700 bg-gradient-to-r from-[#008cd6] to-[#0054ad]   p-2 text-sm font-semibold text-white md:ml-16"
                        : "z-10 my-2 flex flex-none items-center justify-center rounded-full border  border-gray-400 bg-white p-2 text-sm font-semibold  md:ml-16"
                    }
                    onClick={(e) => setVal("story")}
                  >
                    <img
                      className="mr-1"
                      src={require("../../asset/landingPage/Icon/story.png")}
                      alt="story"
                    />
                    &nbsp;
                    <span>Short Story </span>
                    &nbsp;
                  </button>
                  <hr
                    className={
                      val === "story"
                        ? "absolute left-[22%] hidden w-[19%] border-2 border-dashed border-[#008cd6] md:block"
                        : "absolute left-[22%] hidden w-[19%] border-2 border-dashed md:block"
                    }
                  />
                </div>
                <div className="flex flex-none items-center">
                  <button
                    className={
                      val === "product"
                        ? "z-10 my-2 flex flex-none cursor-pointer items-center justify-center rounded-full  border border-blue-700 bg-gradient-to-r from-[#008cd6] to-[#0054ad]   p-2 text-sm font-semibold text-white md:ml-16"
                        : "z-10 my-2 flex flex-none items-center justify-center rounded-full border  border-gray-400 bg-white p-2 text-sm font-semibold  md:ml-16"
                    }
                    onClick={(e) => setVal("product")}
                  >
                    <img
                      className="mr-1"
                      src={require("../../asset/landingPage/Icon/product.png")}
                      alt="product desc"
                    />
                    &nbsp;
                    <span>Product Description </span>
                    &nbsp;
                  </button>
                  <hr
                    className={
                      val === "product"
                        ? "absolute left-[28%] hidden w-[18%] border-2 border-dashed border-[#008cd6] md:block"
                        : "absolute left-[28%] hidden w-[18%] border-2 border-dashed md:block"
                    }
                  />
                </div>
              </div>
            </div>
            <div class="order-1 basis-full md:order-2 md:basis-4/12">
              <img
                src={require("../../asset/landingPage/cat.png")}
                alt="cat"
                className="m-auto md:-mt-12 md:scale-105"
              />
            </div>
            <div class="relative order-3 flex basis-full items-center md:order-3 md:basis-4/12">
              <hr className="absolute -left-[13%] hidden w-[100%] border-2 border-dashed border-blue-700 md:block" />
              <div className="relative m-auto flex w-10/12 flex-col items-center justify-end rounded-md border border-2 border-blue-700 bg-white p-3 md:left-40 md:w-60">
                <div dangerouslySetInnerHTML={{ __html: channelVal[val] }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UnlockSection;
