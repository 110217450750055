import React, { useEffect, useLayoutEffect, useRef } from "react";

function CreativeInAction(props) {
  // const videoRef = useRef(null);
  // let observer;
  // useLayoutEffect(() => {
  //   // Initialize the Intersection Observer with a callback function
  //   observer = new IntersectionObserver(onIntersection, { threshold: 0.5 });

  //   // Observe the video element
  //   observer.observe(videoRef.current);

  //   // Cleanup when the component unmounts
  //   return () => {
  //     if (observer) {
  //       observer.disconnect();
  //     }
  //   };
  // }, []);

  // const onIntersection = (entries) => {
  //   entries.forEach((entry) => {
  //     if (entry.isIntersecting) {
  //       // When the video enters the viewport, play it
  //       videoRef.current.play();
  //     } else {
  //       // When the video leaves the viewport, pause it
  //       videoRef.current.pause();
  //     }
  //   });
  // };

  const videoRef = useRef(null);
  let observer;

  const onIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Check if the user has interacted with the document
        if (document.visibilityState === "visible" && document.hasFocus()) {
          // When the video enters the viewport and the user has interacted, play it
          console.log(videoRef.current);
          if (videoRef.current.length !== 0) {
            videoRef.current.play();
          } else {
            videoRef.current.pause();
          }
        }
      } else {
        // When the video leaves the viewport, pause it
        videoRef.current.pause();
      }
    });
  };

  // useLayoutEffect(() => {
  //   // Initialize the Intersection Observer with a callback function
  //   observer = new IntersectionObserver(onIntersection, { threshold: 0.5 });

  //   // Observe the video element
  //   observer.observe(videoRef.current);

  //   // Cleanup when the component unmounts
  //   return () => {
  //     if (observer) {
  //       observer.disconnect();
  //     }
  //   };
  // }, []);

  // Add a click event listener to the document to detect user interaction
  const handleDocumentClick = () => {
    // Remove the click event listener after the first interaction
    document.removeEventListener("click", handleDocumentClick);

    // Play the video when the user interacts
    // videoRef.current.play();
  };

  // Listen for the first user interaction
  document.addEventListener("click", handleDocumentClick);

  return (
    <>
      <section className="mt-10 bg-black py-16 md:mt-0 md:h-full md:py-20">
        <div className=" m-auto md:container">
          <h4 className="mb-8 text-center text-3xl font-semibold text-white md:text-5xl">
            See CreativeCat in Action
          </h4>
          {/* <video
            id="video1"
            width="100%"
            className=" m-auto max-w-4xl"
            ref={videoRef}
          >
            <source
              src={require("../../asset/video/Product Intro Video.mp4")}
              type="video/mp4"
              muted="muted"
            />
            Your browser does not support HTML video.
          </video> */}
          <div className="m-auto h-[520px] w-[60%] max-w-full max-md:h-[270px] max-md:w-[100%]">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/AiqT6R2AiYE?si=EIoqAQ0yFLWZ8gJQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreativeInAction;
