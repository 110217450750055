import React from "react";

function DiscoverSection(props) {
  return (
    <section className="p-5 md:py-20" ref={props.usecaseRef}>
      <div className=" m-auto md:container">
        <h2 className="my-2 text-center text-3xl  font-semibold   leading-tight text-black  md:text-6xl">
          Discover How CreativeCat <br />
          Serves Diverse Industries
        </h2>
        <div className="m-auto flex flex-col  p-5 md:mt-10 md:w-8/12 md:p-0">
          <div className="grid gap-4 md:grid-cols-2">
            <div className="h-auto rounded-2xl  bg-cardbg7 bg-cover bg-center bg-no-repeat p-12 py-32 text-center text-white">
              <h3 className="mb-5 text-4xl ">
                Optimize Social <br /> Media Presence
              </h3>
              <p className="text-base">
                Social media managers can utilize CreativeCat to maintain a
                vibrant and up-to-date online presence. Automatically generate
                posts that resonate with your audience, ensuring a consistent
                and engaging social media feed.
              </p>
            </div>
            <div className="h-auto rounded-2xl  bg-cardbg8 bg-cover bg-center bg-no-repeat p-12 py-32 text-center text-white">
              <h3 className="mb-5 text-4xl ">
                Drive E-commerce <br /> Success
              </h3>
              <p className="text-base">
                E-commerce businesses can benefit from CreativeCat by generating
                product descriptions, promotional content, and blog posts to
                captivate online shoppers. Our tool ensures your online store
                stays active and your customers stay engaged.
              </p>
            </div>
          </div>
          <div className=" mt-4 overflow-hidden rounded-2xl">
            <div className="flex flex-col">
              <div>
                <img
                  src={require("../../asset/landingPage/cardbg3.png")}
                  alt="banner"
                  className="bg-black "
                />
              </div>
              <div className="h-auto  bg-black bg-cover bg-center bg-no-repeat p-8 text-white  md:p-12">
                <h3 className="mb-5 text-4xl ">Enhance Marketing Campaigns</h3>
                <p className="text-base">
                  CreativeCat empowers marketing professionals to supercharge
                  their campaigns with consistently engaging content. Whether
                  it's social media updates, email newsletters, or blog posts,
                  our tool streamlines content creation, saving time and
                  boosting engagement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DiscoverSection;
