import React, { useEffect } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function TermsofService(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="py-10">
        <div className="m-auto md:container">
          <div className="m-auto mb-8 flex  w-10/12 items-center justify-between border-b-2 border-slate-400 py-5">
            <Link to="/">
              <img
                alt="creativeCat"
                className="logoWhite"
                width="140px"
                src={require("../../src/asset/CreativecatLogo.png")}
              ></img>
            </Link>
            <h4 className="text-2xl font-semibold">Terms of Service</h4>
          </div>
          <div className="ml-auto mr-auto w-8/12 font-semibold" md="8">
            <h3 className="mb-6 text-2xl">
              Welcome to CreativeCat! Please read these Terms of Service
              ("Terms") carefully before using our web app.
            </h3>
            <h4 className="mb-6">
              By accessing or using CreativeCat, you agree to comply with and be
              bound by these Terms. If you do not agree with these Terms, please
              do not use our services.
            </h4>

            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">1. Refund Policy</h5>
              <p className="text-sm  font-medium">
                We understand that circumstances may change, and you may wish to
                discontinue your subscription. However, please note that we do
                not offer refunds for any portion of your subscription fees.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">2. Cancellation Policy</h5>
              <p className="text-sm  font-medium">
                You have the freedom to cancel your plan at any time. We do not
                impose any long-term commitments, and there are no cancellation
                fees. When you cancel your plan, your access to CreativeCat will
                continue until the end of your current billing cycle, after
                which your subscription will be terminated.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">3. Termination</h5>
              <p className="text-sm  font-medium">
                We reserve the right to terminate or suspend your account or
                access to CreativeCat at our discretion, with or without cause,
                and with or without notice. In the event of a violation of these
                Terms or any applicable laws, we may take appropriate action,
                including but not limited to terminating your account.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">4. Changes to Terms</h5>
              <p className="text-sm  font-medium">
                We may update or modify these Terms from time to time, and any
                changes will be effective immediately upon posting. It is your
                responsibility to review these Terms periodically for any
                updates.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">5. Privacy</h5>
              <p className="text-sm  font-medium">
                Your use of CreativeCat is also governed by our Privacy Policy,
                which can be found here.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">6. Contact Us</h5>
              <p className="text-sm  font-medium">
                If you have any questions or concerns regarding these Terms or
                any other aspect of CreativeCat, please feel free to contact us
                at{" "}
                <a
                  href="mailto:support@creativecat.io"
                  className="text-blue-700"
                >
                  support@creativecat.io
                </a>
                .
              </p>
            </div>
            <br />
            <br />
            <h5 className="text-center">Thank you for using CreativeCat!</h5>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default TermsofService;
