import React from "react";

function AboutCreative(props) {
  return (
    <>
      <section className="md:py-10">
        <div className=" m-auto md:container ">
          <div className="m-auto flex w-full flex-col p-5 md:w-8/12 md:flex-row md:p-0">
            <div className="basis-8/12">
              <h2 className="m-auto my-2 text-3xl font-semibold leading-tight text-black md:mb-10 md:text-6xl">
                About CreativeCat
              </h2>
              <p className="text-lg font-semibold leading-normal text-gray-400 md:w-8/12">
                We're passionate about empowering{" "}
                <b className="font-semibold text-black">content creators</b> to
                bring their{" "}
                <b className="font-semibold text-black">ideas to life</b>. Our{" "}
                <b className="font-semibold text-black">user-centric design</b>{" "}
                and commitment to user privacy and security set us apart. Join
                our community of creators today.
              </p>
            </div>
            <div className="basis-4/12">
              <img
                src={require("../../asset/landingPage/catlap.png")}
                alt="close"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutCreative;
