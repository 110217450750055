import React, { useEffect } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function PrivacyPolicy(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <div>PrivacyPolicy</div>
    <>
      <section className="py-10">
        <div className="m-auto md:container">
          <div className="m-auto mb-8 flex  w-10/12 items-center justify-between border-b-2 border-slate-400 py-5">
            <Link to="/">
              <img
                alt="creativeCat"
                className="logoWhite"
                width="140px"
                src={require("../../src/asset/CreativecatLogo.png")}
              ></img>
            </Link>
            <h4 className="text-2xl font-semibold">Privacy Policy</h4>
          </div>
          <div className="ml-auto mr-auto w-8/12 font-semibold" md="8">
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">1. Introduction</h5>
              <p className="text-sm  font-medium">
                Welcome to CreativeCat ("we," "our," or "us"). At CreativeCat,
                we are committed to protecting your privacy and ensuring the
                security of your personal information. This Privacy Policy
                explains how we collect, use, disclose, and safeguard your
                information when you use our services.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">2. Information We Collect</h5>
              <p className="text-sm  font-medium">
                We may collect the following types of information:
              </p>
              <p className="text-sm  font-medium">
                <b>Personal Information</b>: We may collect personal information
                such as your name, email address, and contact information when
                you create an account or contact us.
              </p>
              <p className="text-sm  font-medium">
                <b>Usage Information</b>: We may collect information about your
                usage of our services, including log data, device information,
                and usage patterns.
              </p>
              <p className="text-sm  font-medium">
                <b>Content</b>: When you use our services, we may collect and
                store the content you create, including text, images, and other
                media.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">
                3. How We Use Your Information
              </h5>
              <p className="text-sm  font-medium">
                We may use the information we collect for the following
                purposes:
              </p>
              <p className="text-sm  font-medium">
                <b>To Provide Services</b>: We use your information to provide
                you with access to CreativeCat and its features, including
                content generation.
              </p>

              <p className="text-sm  font-medium">
                <b>To Communicate</b>: We may use your contact information to
                communicate with you, respond to inquiries, and send important
                updates.
              </p>
              <p className="text-sm  font-medium">
                To Improve Our Services: We may analyze usage patterns and
                feedback to improve our services and develop new features.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">
                4. Disclosure of Your Information
              </h5>
              <p className="text-sm  font-medium">
                We do not sell or rent your personal information to third
                parties. However, we may disclose your information in the
                following circumstances: Service Providers: We may share your
                information with third-party service providers who assist us in
                providing and maintaining our services. These service providers
                are contractually obligated to protect your information. Legal
                Requirements: We may disclose your information when required to
                comply with legal obligations, respond to legal processes, or
                protect our rights, privacy, safety, or property.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">5. Security Measures</h5>
              <p className="text-sm  font-medium">
                We employ reasonable security practices and measures to protect
                your information from unauthorized access, disclosure,
                alteration, or destruction. However, please be aware that no
                method of transmission over the internet or electronic storage
                is entirely secure, and we cannot guarantee absolute security.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">6. Your Choices</h5>
              <p className="text-sm  font-medium">
                You have the right to access, correct, or delete your personal
                information. You can update your account information or request
                data deletion by contacting us at &nbsp;
                <a
                  href="mailto:support@creativecat.io"
                  className="text-blue-700"
                >
                  support@creativecat.io
                </a>
                .
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">
                7. Changes to this Privacy Policy
              </h5>
              <p className="text-sm  font-medium">
                We may update this Privacy Policy to reflect changes in our
                practices and services. Any changes will be posted on this page
                with an updated effective date.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">8. Contact Us</h5>
              <p className="text-sm  font-medium">
                If you have any questions, concerns, or requests regarding this
                Privacy Policy or your personal information, please contact us
                at{" "}
                <a
                  href="mailto:support@creativecat.io"
                  className="text-blue-700"
                >
                  support@creativecat.io
                </a>
                .
              </p>
            </div>

            <br />
            <br />
            <h5 className="text-center">Thank you for using CreativeCat!</h5>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
