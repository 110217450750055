import React from "react";

function Companylist(props) {
  return (
    <section className=" bg-slate-200 py-10 md:py-20">
      <div className=" m-auto md:container">
        <h4 className="mb-16 text-center text-3xl font-semibold md:text-5xl">
          Trusted by innovative companies worldwide
        </h4>
        <div className="grid grid-cols-2 items-center gap-5 md:flex  md:justify-around md:gap-0 ">
          <img
            className="m-5 w-40"
            src={require("../../asset/landingPage/company/1.png")}
            alt="close"
          />
          <img
            className="m-5 w-40"
            src={require("../../asset/landingPage/company/2.png")}
            alt="close"
          />
          <img
            className="m-5 w-40"
            src={require("../../asset/landingPage/company/3.png")}
            alt="close"
          />
          <img
            className="m-5 w-40"
            src={require("../../asset/landingPage/company/4.png")}
            alt="close"
          />
          <img
            className="m-5 w-40"
            src={require("../../asset/landingPage/company/5.png")}
            alt="close"
          />
        </div>
      </div>
    </section>
  );
}

export default Companylist;
