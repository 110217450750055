import React, { useState } from "react";
import ModalVideo from "react-modal-video";

function UnlimitedCustomTemplate(props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className=" bg-black pb-10 pt-0 md:py-20">
        <div className=" m-auto md:container">
          <div className="mt-5 inline-block p-5 md:mt-20 md:pl-60">
            <h4 className="mb-8 text-3xl   font-semibold leading-snug text-white md:text-6xl">
              Unlimited Custom <br /> Template Creation
            </h4>
            <p className="text-lg font-semibold leading-normal text-gray-400 md:w-6/12">
              Express your brand's unique style with unlimited custom template
              creation. Whether you're aiming for consistency or{" "}
              <b className="font-semibold text-white">
                showcasing your creativity
              </b>
              , CreativeCat's features lets you design and save templates that
              perfectly{" "}
              <b className="font-semibold text-white">represent your brand</b>.
              Tailor your content with ease, one template at a time.
            </p>
          </div>
          <div className="my-8 flex items-center justify-center">
            <img
              src={require("../../asset/landingPage/fullimagesection.png")}
              alt="plus"
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              className="mb-0 mt-2 flex items-center justify-between rounded-full bg-[#424242] p-2 px-2 pl-6 text-lg font-semibold text-white md:m-14"
              onClick={(e) => {
                setOpen(true);
              }}
            >
              Watch &nbsp;&nbsp;
              <img
                src={require("../../asset/landingPage/plus.png")}
                alt="plus"
                className="w-10"
              />
            </button>
          </div>
        </div>
      </section>

      {/* {props.movelVal === 3 && (
        <VideoModel movelVal={props.movelVal} setMovelVal={props.setMovelVal} />
      )} */}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="fTmosTa3QYI"
        onClose={() => setOpen(false)}
      />
    </>
  );
}

export default UnlimitedCustomTemplate;
