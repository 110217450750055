import React, { useEffect } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Support(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="py-10">
        <div className="m-auto md:container">
          <div className="m-auto mb-8 flex  w-10/12 items-center justify-between border-b-2 border-slate-400 py-5">
            <Link to="/">
              <img
                alt="creativeCat"
                className="logoWhite"
                width="140px"
                src={require("../../src/asset/CreativecatLogo.png")}
              ></img>
            </Link>
            <h4 className="text-2xl font-semibold">Support</h4>
          </div>
          <div
            className="ml-auto mr-auto min-h-[500px] w-8/12 font-semibold"
            md="8"
          >
            {/* <h4 className="">Effective Date: [Insert Date]</h4>
                  <br />
                  <br /> */}

            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">Email Support</h5>
              <p className="text-sm  font-medium">
                For personalized assistance, contact our support team at&nbsp;
                <a href="emailto:support@creativecat.io">
                  support@creativecat.io
                </a>
                . We aim to respond promptly to address your inquiries and
                provide the help you need.
              </p>
            </div>
            {/* <div className="points">
              <h5 className="mb-1 mt-5 text-lg">Mobile Number</h5>
              <p className="text-sm  font-medium">
                Reach us directly at{" "}
                <a href="tel:+919790995474">+91 9790995474</a> for urgent
                queries or assistance. Please note that our office hours are
                Monday to Friday, 9 AM to 6 PM (GMT).
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">Office Address</h5>
              <p className="text-sm  font-medium">
                Visit us at 9A, MUTHAMIZH NAGAR KADAPERI, <br />
                SOUTH KULAKKARAI STREET, <br /> Kancheepuram, Tamil Nadu -
                600045.
                <br />
                Please note: that our office is open Monday to Friday, 9 AM to 6
                PM (GMT).
              </p>
            </div> */}
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">FAQs</h5>
              <p className="text-sm  font-medium">
                Explore our Frequently Asked Questions for quick answers.
                Covering a range of topics, it's a handy resource to
                troubleshoot common issues or queries.
              </p>
            </div>
            <div className="points">
              <h5 className="mb-1 mt-5 text-lg">Feedback</h5>
              <p className="text-sm  font-medium">
                We value your input! Share your thoughts, suggestions, or
                concerns via email. Your feedback helps us enhance CreativeCat
                for you and our community.
              </p>
            </div>

            <br />
            <br />
            <h5 className="text-center">Thank you for choosing Creativecat.</h5>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Support;
