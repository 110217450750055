import React from "react";

function UserSection(props) {
  return (
    <section className="bg-black py-10">
      <div className=" m-auto md:container ">
        <h2 className="my-2 text-center text-3xl font-semibold leading-tight  text-white  md:mb-10 md:text-6xl">
          What Our Users Say
        </h2>
        <div className="flex overflow-auto">
          <div className="p-8 font-semibold">
            <img
              src={require("../../asset/landingPage/users/user1.png")}
              alt="banner"
              className="h-64 w-full rounded-xl object-cover md:h-auto  md:w-auto md:object-none"
            />
            <p className="w-60 py-5 text-white">
              CreativeCat is a game-changer for my marketing team. The automated
              social media content generator has saved us hours of work, and the
              dynamic idea generator keeps our campaigns fresh and engaging
            </p>
            <p className="text-gray-400">
              Sarah Smith, <br />
              Marketing Manager
            </p>
          </div>
          <div className="p-8 font-semibold">
            <img
              src={require("../../asset/landingPage/users/user2.png")}
              alt="banner"
              className="h-64 w-full rounded-xl object-cover md:h-auto  md:w-auto md:object-none"
            />
            <p className="w-60 py-5 text-white">
              As an educator, I rely on CreativeCat to simplify content creation
              for my students. The easy file management and custom template
              creation features make my job a breeze. It's a must-have tool for
              any teacher!
            </p>
            <p className="text-gray-400">
              David Johnson,
              <br />
              High School Teacher
            </p>
          </div>
          <div className="p-8 font-semibold">
            <img
              src={require("../../asset/landingPage/users/user3.png")}
              alt="banner"
              className="h-64 w-full rounded-xl object-cover md:h-auto  md:w-auto md:object-none"
            />
            <p className="w-60 py-5 text-white">
              I've been blogging for years, and CreativeCat has made my life so
              much easier. The YouTube content generator gives me new video
              ideas, and the paraphrase text tool helps me create unique blog
              posts. Highly recommended!
            </p>
            <p className="text-gray-400">
              Emily White,
              <br />
              Blogger
            </p>
          </div>
          <div className="p-8 font-semibold">
            <img
              src={require("../../asset/landingPage/users/user4.png")}
              alt="banner"
              className="h-64 w-full rounded-xl object-cover md:h-auto  md:w-auto md:object-none"
            />
            <p className="w-60 py-5 text-white">
              CreativeCat's elaborate feature has been a lifesaver for my legal
              blog. It helps me dive deep into complex legal topics. The
              corporate legal advisor micro-app also ensures my content is
              legally sound.
            </p>
            <p className="text-gray-400">
              Mark Anderson,
              <br />
              Legal Blogger
            </p>
          </div>
          <div className="p-8 font-semibold">
            <img
              src={require("../../asset/landingPage/users/user5.png")}
              alt="banner"
              className="h-64 w-full rounded-xl object-cover md:h-auto  md:w-auto md:object-none"
            />
            <p className="w-60 py-5 text-white">
              I'm a small business owner, and CreativeCat has been a real asset.
              The translator micro-app lets me reach a global audience, and the
              easy read summarizer streamlines my content for busy customers.
              It's a fantastic tool for entrepreneurs!
            </p>
            <p className="text-gray-400">
              Lisa Brown,
              <br />
              Small Business Owner
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserSection;
