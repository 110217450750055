import React, { useState } from "react";
import ModalVideo from "react-modal-video";

function GameChanging(props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <section className=" bg-black py-10 pt-0">
        <div className=" m-auto md:container">
          <div className="flex justify-center">
            <h4 className="flex flex-col text-center text-3xl font-semibold leading-loose text-white max-md:leading-snug md:absolute md:text-7xl  ">
              <span className="mb-4 max-md:mb-0">Game-changing</span>

              <span class="bg-gradient-to-t from-blue-300 to-white bg-clip-text text-transparent">
                Creative Opportunities
              </span>
            </h4>
          </div>

          <img
            src={require("../../asset/landingPage/laptop4.png")}
            alt="laptop"
            // className="hidden md:block"
          />

          {/* <img
          src={require("../../asset/landingPage/responsive/CreativeCatProdwitputbg.png")}
          alt="laptop"
          className="block md:hidden"
        /> */}
          <div className="bg-gradient-from-tl  m-auto m-auto max-w-4xl rounded p-7 text-white md:border md:border-2 md:border-gray-300">
            <h5 className="my-5 text-3xl    leading-normal  md:text-6xl">
              Automated Daily Content
            </h5>
            <div className="flex flex-col md:flex-row">
              <div className="basis-full md:basis-7/12">
                <p className="text-lg  font-semibold leading-normal text-gray-400">
                  CreativeCat takes the hassle out of daily content creation.
                  With our automated feature, you can effortlessly{" "}
                  <b className="font-semibold text-white">
                    generate social media content
                  </b>{" "}
                  that perfectly aligns with your brand identity. Say goodbye to
                  content planning stress and hello to a{" "}
                  <b className="font-semibold  text-white">
                    constant stream of engaging posts
                  </b>
                  .
                </p>
                <div>
                  <h5 className="relative mt-8 text-center text-2xl font-semibold md:-right-20 md:mb-16 md:text-right md:text-3xl">
                    <span className="text-emerald-400">Brand</span>
                    <span className="text-blue-500">&nbsp;+&nbsp;Creative</span>
                    <span className="text-violet-500">
                      &nbsp;+&nbsp;Customized
                    </span>
                    <span className="hidden text-green-500 md:inline-block ">
                      {" "}
                      ={" "}
                    </span>

                    {/* <span className="absolute flex w-full items-center justify-center md:relative">
                    <span className="absolute w-10 rotate-90 text-4xl text-green-500 md:relative md:w-auto md:rotate-0 md:text-3xl">
                      {" "}
                      ={" "}
                    </span>
                  </span> */}
                  </h5>
                </div>
              </div>
              <div className="basis-full md:relative md:basis-5/12">
                <div className="left-24 top-2 flex w-full flex-col items-center justify-center bg-black p-4 md:absolute">
                  <div className="relative my-1 h-14 w-[90%] rounded bg-gradient-to-r from-green-500 to-green-400"></div>
                  <div className="relative my-1 h-14 w-[95%] rounded bg-gradient-to-r from-green-500 to-green-400"></div>
                  <div className="relative my-1 flex h-14 w-[100%] items-center justify-center rounded bg-gradient-to-r from-green-500 to-green-400 text-xl font-semibold text-white">
                    Daily Content
                  </div>
                  <div className="relative my-1 h-14 w-[95%] rounded bg-gradient-to-r from-green-500 to-green-400"></div>
                  <div className="relative my-1 h-14 w-[90%] rounded bg-gradient-to-r from-green-500 to-green-400"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="m-0 mb-0 flex items-center justify-between rounded-full bg-[#424242] p-2 px-2 pl-6 text-lg font-semibold text-white md:m-14"
              // onClick={setMovelVal(1)}
              onClick={(e) => {
                setOpen(true);
              }}
            >
              Watch &nbsp;&nbsp;
              <img
                src={require("../../asset/landingPage/plus.png")}
                alt="plus"
                className="w-10"
              />
            </button>
          </div>
        </div>
      </section>
      {/* <VideoModel /> */}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="E_gh5pbv5bc"
        onClose={() => setOpen(false)}
      />
    </>
  );
}

export default GameChanging;
